
import { defineComponent, ref, onMounted, watch } from "vue"
//import { useRoute } from "vue-router"
import { ElForm } from "element-plus"
//import ApiService from "@/core/services/ApiService"

import { InternalRuleItem } from "async-validator"
import { UserAdd } from "@/core/models/User"
import { useRoute, useRouter } from "vue-router"
import ApiService from "@/core/services/ApiService"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  name: "invitation",
  setup() {
    const route = useRoute()
    const router = useRouter()

    type FormInstance = InstanceType<typeof ElForm>
    const formSize = ref("")
    const ruleFormRef = ref<FormInstance>()

    const user = ref<UserAdd>({} as UserAdd)

    onMounted(async () => {
      if (route.params.has_register != "False") {
        await approveInvitation({
          token: route.params.invitation_code,
          has_register: route.params.has_register,
        })
      }
    })

    const approveInvitation = async data => {
      await ApiService.post("invitation/approve", data)
      router.push({ name: "sign-in" })
    }

    watch(
      () => ({
        invitation_code: route.params.invitation_code,
        has_register: route.params.has_register,
      }),
      (newValue, oldValue) => {
        console.log(newValue, oldValue)
      }
    )

    const passwordPolicyFormat = new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d]{8,}$/)

    const submitForm = (formEl: FormInstance | undefined) => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          await approveInvitation({
            ...user.value,
            token: route.params.invitation_code,
            has_register: route.params.has_register,
          })
        }
      })
    }

    const validatePass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") callback(new Error("Lütfen Yeni Şifre Giriniz!"))
      else if (value != user.value.checkPassword) callback(new Error("Şifreler Aynı Olmalıdır"))
      else if (!passwordPolicyFormat.test(value))
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      else {
        if (user.value.checkPassword != "" && ruleFormRef.value)
          ruleFormRef.value.validateField("checkPassword", () => null)

        callback()
      }
    }

    const validateCheckPass = (
      rule: InternalRuleItem,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value: any,
      callback: (error?: string | Error | undefined) => void
    ) => {
      if (value === "") callback(new Error("Lütfen Yeni Şifrenizi Tekrar Giriniz!"))
      else if (value != user.value.password) callback(new Error("Şifreler Aynı Olmalıdır"))
      else if (!passwordPolicyFormat.test(value))
        callback(new Error("En az 8 karakter olmalı, 1 büyük harf ve 1 rakam içermelidir!"))
      else {
        if (user.value.password != "" && ruleFormRef.value)
          ruleFormRef.value.validateField("password", () => null)
        callback()
      }
    }

    const rules = {
      password: [{ validator: validatePass, trigger: "blur" }],
      checkPassword: [{ validator: validateCheckPass, trigger: "blur" }],
      userName: getRule(RuleTypes.TEXT50, "Kullanıcı Adı"),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      surname: getRule(RuleTypes.TEXT50, "Soyad"),
      citizenId: getRule(RuleTypes.TCID),
      birthDate: getRule(RuleTypes.DATE),
    }

    return {
      user,
      rules,
      formSize,
      ruleFormRef,
      submitForm,
    }
  },
})
